import { format, fromZonedTime, toZonedTime } from 'date-fns-tz'
import { nanoid } from 'nanoid'


// 0 = gray
// 1,2,3... = generated color
export function generateColor(index) {
   return [
      '#0075BE',
      '#000F1A',
      '#be0000',
      '#9fa09f',
      '#006e35',
      '#f8941d',
      '#91278e',
      '#8cc63f',
      '#a8857b',
      '#00a99d',
      '#000075',
      '#333333',
   ][index] || '#fff'


   // return ["#e6194B", "#4363d8", "#f58231", "#42d4f4", "#f032e6", "#3cb44b", "#ffe119", "#fabebe", "#469990", "#e6beff", "#9A6324", "#fffac8", "#800000", "#aaffc3", "#000075", "#a9a9a9", "#ffffff", "#000000"][index] || "#fff"

   // distinct color array from web (99% accessibility)
   // return ["#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#f58231", "#42d4f4", "#f032e6", "#fabebe", "#469990", "#e6beff", "#9A6324", "#fffac8", "#800000", "#aaffc3", "#000075", "#a9a9a9", "#ffffff", "#000000"][index] || "#fff"
}

export function getLanguageString(obj) {
   if (obj && obj.en) {
      return (obj.en)
   }
   return null

}


export function round(value, precision) {
   const multiplier = 10 ** (precision || 0)
   return Math.round(value * multiplier) / multiplier
}


export function removeObjectFromArray(array, obj) {

   const index = array.indexOf(obj)
   if (index !== -1) {
      array.splice(index, 1)
   }
   return array
}

export function dateToString(date, timeZone, showTime, showSeconds, hideYear) { // TODO: Use navigator.language
   const dateFix = timeZone ? toZonedTime(date, timeZone) : date
   const dateStr = hideYear ? 'd.M.' : 'd.M.yyyy'
   if (!showTime) {
      return format(dateFix, dateStr)
   }
   const timeStr = showSeconds ? 'HH:mm:ss' : 'HH:mm'
   return format(dateFix, `${dateStr} ${timeStr}`)

}

// hh:mm or hh:mm:ss if seconds
export const dateToTimeString = (date, timeZone, seconds) => {
   const fixedDate = timeZone ? toZonedTime(date, timeZone) : date
   return format(fixedDate, `HH:mm${seconds ? ':ss' : ''}`)
}

/**
 * Adjusts REACT DATEPICKER date (always in local time) to wanted timezone by adding minutes offset. USE ONLY FOR REACT DATEPICKER.
 * @param {Date} date
 * @param {string} timeZone
 */
export const modifyDateToTimezone = (date, timeZone) => fromZonedTime(date, timeZone)


export function getBeginningOfDate(date) {
   const d = new Date(date)
   d.setHours(0)
   d.setMinutes(0)
   d.setSeconds(0)
   d.setMilliseconds(0)
   return d
}

export function getEndOfDate(date) {
   const d = new Date(date)
   d.setHours(23)
   d.setMinutes(59)
   d.setSeconds(59)
   d.setMilliseconds(999)
   return d
}


export function dictionaryToArray(dict) {
   const array = []
   for (const key in dict) {
      array.push(dict[key])
   }
   return array
}



export function detectBrowser() {
   const ua = window.navigator.userAgent
   const msie = ua.indexOf('MSIE ')
   if (msie > 0) {
      // IE 10 or older => return version number
      // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);

      return 'MSIE'
   }

   const trident = ua.indexOf('Trident/')
   if (trident > 0) {
      // IE 11 => return version number
      // var rv = ua.indexOf('rv:');
      // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      return 'IE11'
   }

   const edge = ua.indexOf('Edge/')
   if (edge > 0) {
      // Edge (IE 12+) => return version number
      // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      return 'EDGE'
   }

   // other browser
   return false
}


export function colorLuminance(hex, lum) {

   // validate hex string
   hex = String(hex).replace(/[^0-9a-f]/gi, '')
   if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
   }
   lum = lum || 0

   // convert to decimal and change luminosity
   let rgb = '#'; let c; let
      i
   for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16)
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
      rgb += (`00${c}`).substr(c.length)
   }

   return rgb
}

export function hexToRgb(hex) {
   let c
   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length == 3) {
         c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = `0x${c.join('')}`
      return {
         r: (c >> 16) & 255,
         g: (c >> 8) & 255,
         b: c & 255,
      }
   }
   throw new Error(`Bad Hex "${hex}"`)
}


export function listenToClassChanges(element, callback) {
   const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
         if (mutation.attributeName === 'class') {
            callback(mutation.target.className.split(' '))
         }
      })
   })
   observer.observe(element, {
      attributes: true,
   })
}


export function sortedStringFromArray(array) {
   array.sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1
      return 1
   })
   let str = ''
   if (array.length > 0) {
      array.forEach((item, index) => {
         str += item
         if (index !== array.length - 1) str += ', '
      })
   }
   return str
}


/**
 * @param src
 * @returns {Promise<Image>}
 */
export function loadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
   })
}


export function generateRandomStringId() {
   return nanoid(16)
}


/**
 * Converts passed style array to object or returns passed object
 * @param {any=} style
 * @returns {Object}
 */
export function styleToObject(style) {
   if (style instanceof Array) {
      let styleObject = {}
      style.forEach((s) => {
         if (!s) return
         styleObject = {
            ...styleObject,
            ...s,
         }
      })
      return styleObject
   }
   return style || {}

}


/* Converts and rounds value from tool to correct format and returns a string that is used to display results. If componenRoundPrecision is available, use it instead (e.g. some round precision can be defined to charts)*/
export function getConvertedAndRoundedValue(resultModelElement, value, componentRoundPrecision = undefined) {
   // if boolean value
   if (value === true) return 'true'
   if (value === false) return 'false'

   // if other than boolean (number)
   const convertedValue = (value || value === 0) ? (resultModelElement.multiplier ? (resultModelElement.multiplier * value) : value) : undefined // Make conversion if multiplier defined to element in tools-config
   const precision = componentRoundPrecision || ((resultModelElement.round || resultModelElement.round === 0) ? resultModelElement.round : undefined)
   const roundedValue = (convertedValue || convertedValue === 0) ? ((precision || precision === 0) ? round(convertedValue, precision) : convertedValue) : undefined
   return roundedValue
}


/**
 * Remove object keys with undefined value.
 * Additional clean options can be set via options parameter.
 * The object isn't copied so keys are removed from the original object.
 *
 * @param {Object} obj
 * @param {{
 *    removeNull?: boolean,
 *    removeEmptyString?: boolean,
 * }} [options]
 */
export function cleanObject(obj, options) {
   const { removeNull, removeEmptyString } = options || {}
   Object.keys(obj).forEach((key) => {
      if (
         (obj[key] === undefined)
         || (removeNull && obj[key] == null)
         || (removeEmptyString && obj[key] === '')
      ) delete obj[key]
   })
   return obj
}
