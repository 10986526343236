import { selectSelectedTool } from '@ava/react-common/store/reducers/root-reducer'
import { getLanguageString, round } from '@ava/react-common/utils/Helper'
import { toZonedTime } from 'date-fns-tz'
import XLSX from 'xlsx'
import store from '../store'


/**
 * Downloads an Excel file that contains the analyses
 * @param {import('@ava/react-common/models').Analysis[]} analyses
 */
export function exportAnalysesToXLSX(analyses) {
   const state = store.getState()
   const tool = selectSelectedTool(state)
   const timeZone = state.timeZone
   const resultModel = tool.resultModels[0] // TODO: Handle multiple result models - now assumes that only one result model exists - e.g. export different versions in different sheets

   const wb = XLSX.utils.book_new()
   wb.Props = {
      Title: 'AVA',
      Subject: 'Exported Data',
      Author: '-',
      CreatedDate: new Date(),
   }
   wb.SheetNames.push(tool.name)

   const wsData = []

   const titleRow = ['AnalysisId', 'OriginalImageId', 'ResultImageId', 'Timestamp', 'Date Analyzed', 'Processing unit', 'Position', 'Tags', 'Comment', 'Error'] // Note: Author removed

   for (const key in resultModel.elements) {
      const element = resultModel.elements[key]
      const name = `${getLanguageString(element.name)} ${element.unit}`
      titleRow.push(name)
   }

   wsData.push(titleRow)

   analyses.forEach((analysis) => {
      const unit = state.units[analysis.unit]
      const unitName = unit ? unit.name : null

      analysis.items.forEach((item) => {
         const row = []

         const position = state.positions[item.position]
         const positionName = position ? position.name : null

         const tagArray = analysis.tags
            .map((tag) => state.tags[tag]).filter((t) => t)

         let tags = ''
         tagArray.forEach((tag, i) => {
            tags += tag.name + (i + 1 < tagArray.length ? ', ' : '')
         })

         const comment = analysis.comment
         const error = item.error ? item.error.code : null

         row.push(analysis.id)
         row.push(item.imageId)
         row.push(item.result && item.result.imageId)
         row.push({ v: timeZone ? toZonedTime(item.dateTaken, timeZone) : item.dateTaken, t: 'd' })
         row.push({ v: timeZone ? toZonedTime(item.dateAnalyzed, timeZone) : item.dateAnalyzed, t: 'd' })
         // row.push(author)
         row.push(unitName)
         row.push(positionName)
         row.push(tags)
         row.push(comment)
         row.push(error)

         for (const key in resultModel.elements) {
            const roundPrecision = resultModel.elements[key].round
            let value = (item.result && item.result.elements) ? item.result.elements[key] : null
            if (value && roundPrecision != null) {
               value = round(value, roundPrecision)
            }
            row.push(value)
         }
         wsData.push(row)

      })
   })

   const ws = XLSX.utils.aoa_to_sheet(wsData, { dateNF: 'h:mm:ss' })
   wb.Sheets[tool.name] = ws

   XLSX.writeFile(wb, `${tool.id}.xlsx`, { cellDates: true })
}
