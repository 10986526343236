import { scaleTime } from 'd3-scale'
import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { clamp } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Slider, Ticks } from 'react-compound-slider'
import { Handle, Handles, SliderRail, Tick, ZoomSliderRail } from './timeframe-slider-components'

const dayInMS = 86400000


/**
 * @typedef {object} Props
 * @property {[Date, Date]} [timeframe]
 * @property {[Date, Date]} [selection]
 * @property {[Date, Date]} [zoomSelection]
 * @property {("timeframe"|"time")} [selectionType="timeframe"]
 * @property {(from: Date, to: Date) => void} [onSelectionChange] Triggers when cursor is released
 * @property {(from: Date, to: Date) => void} [onZoomSelectionChange]
 * @property {(isBeingChanged: boolean) => void} [onBeingChanged]
 * //prop {(from: Date, to: Date) => void} [onSelectionUpdate] Triggers when cursor is moved
 * @property {object} [style]
 * @property {{ data: number[], color: string }[]} [dataPoints]
 * @property {string} [timeZone]
 * @augments {Component<Props>}
 */
export default class TimeframeSlider extends Component {

   static propTypes = {
      timeframe: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      selectionType: PropTypes.oneOf(['timeframe', 'time']),
      selection: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      zoomSelection: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      onSelectionChange: PropTypes.func,
      onSelectionUpdate: PropTypes.func,
      dataPoints: PropTypes.arrayOf(PropTypes.object),
      isZoomSliderEnabled: PropTypes.bool,
   }

   static defaultProps = {
      selectionType: 'timeframe',
      dataPoints: [],
      isZoomSliderEnabled: true,
   }


   constructor(props) {
      super(props)

      const selectedFrom = (props.selection && +props.selection[0]) || (props.timeframe && +props.timeframe[0])
      const selectedTo = (props.timeframe && props.selectionType === 'timeframe')
         ? (props.selection && +props.selection[1]) || (props.timeframe && +props.timeframe[1])
         : undefined

      this.state = {
         timeframeFrom: props.timeframe?.[0]?.getTime(), // zoom slider min value
         timeframeTo: props.timeframe?.[1]?.getTime(), // zoom slider max value
         timeframeZoomedFrom: props.timeframe?.[0]?.getTime(), // zoom slider selection "from" (timeframe slider min value)
         timeframeZoomedTo: props.timeframe?.[1]?.getTime(), // zoomed slider selection "to" (timeframe slider max value)
         selectedFrom, // timeframe slider selection "from"
         selectedTo, // timeframe slider selection "to"
         numOfTicks: 8,
      }

      this.earlierTickMs = undefined
      this.triggerOnSelectionChangeOnMouseUp = false // This is set to true everytime when timeframe components are being dragged. This variable is checked on window 'mouseup' event
      this.triggerOnZoomSelectionChangeOnMouseUp = false // This is set to true everytime when timeframe zoom slider components are dragged. This variable is checked on window 'mouseup' event
   }

   componentDidMount() {
      window.addEventListener('mouseup', this.onMouseUp)

      // If selection prop is null, call onSelectionChange with default selection
      if (!this.props.selection && this.state.timeframeZoomedFrom) {
         this.triggerOnSelectionChange(this.state.timeframeZoomedFrom, this.state.timeframeZoomedTo)
      }
      // If zoomSelection prop is null, call onZoomSelectionChange with default zoom selection
      if (!this.props.zoomSelection && this.state.timeframeZoomedFrom) {
         this.triggerOnZoomSelectionChange(this.state.timeframeZoomedFrom, this.state.timeframeZoomedTo)
      }
   }

   componentDidUpdate(prevProps, prevState) {
      const { timeframe, selectionType, selection, zoomSelection } = this.props
      const { timeframeFrom, timeframeTo, timeframeZoomedFrom, timeframeZoomedTo, selectedFrom, selectedTo } = this.state

      // Timeframe + selection
      const newSelectionState = {}
      const newTimeframeFrom = timeframe?.[0]?.getTime()
      if ((timeframeFrom !== undefined || newTimeframeFrom !== undefined) && timeframeFrom !== newTimeframeFrom) {
         const newTimeframeFromState = { timeframeFrom: newTimeframeFrom }
         if (timeframeZoomedFrom === undefined
            || prevState.timeframeFrom === undefined
            || timeframeZoomedFrom <= +prevState.timeframeFrom) {
            newTimeframeFromState.timeframeZoomedFrom = newTimeframeFrom
         }
         this.setState(newTimeframeFromState)
         if (!selectedTo) {
            if (selectionType === 'timeframe') {
               newSelectionState.selectedFrom = newTimeframeFrom
            }
         }
      }
      const newTimeframeTo = timeframe?.[1]?.getTime()
      if ((timeframeTo !== undefined || newTimeframeTo !== undefined) && timeframeTo !== newTimeframeTo) {
         const newTimeframeToState = { timeframeTo: newTimeframeTo }
         if (timeframeZoomedTo === undefined
            || prevState.timeframeFrom === undefined
            || timeframeZoomedTo >= +prevState.timeframeTo) {
            newTimeframeToState.timeframeZoomedTo = newTimeframeTo
         }
         this.setState(newTimeframeToState)
         if (!selectedFrom) {
            if (selectionType === 'timeframe') {
               newSelectionState.selectedTo = newTimeframeTo
            } else {
               newSelectionState.selectedFrom = newTimeframeTo
            }
         }
      }
      // -- SELECTION --

      // Zoom selection
      if (zoomSelection !== prevProps.zoomSelection) { // On zoomSelection prop change
         const newZoomSelectionFrom = zoomSelection && +zoomSelection[0]
         const newZoomSelectionTo = zoomSelection && +zoomSelection[1]
         if (timeframeZoomedFrom !== newZoomSelectionFrom || timeframeZoomedTo !== newZoomSelectionTo) {
            this.setState({ // Clamp to selection to timeframe if selection is trying to be set out of bounds
               timeframeZoomedFrom: newZoomSelectionFrom && Math.max(+timeframeFrom, newZoomSelectionFrom),
               timeframeZoomedTo: newZoomSelectionTo && Math.min(newZoomSelectionTo, +timeframeTo),
            })
         }
      }

      // - Selected timeframe out of selected timeframe zoom bounds -
      // time
      if (selectionType === 'time') {
         if (selectedFrom > timeframeZoomedTo) {
            newSelectionState.selectedFrom = timeframeZoomedTo
         }
         if (selectedFrom < timeframeZoomedFrom) {
            newSelectionState.selectedFrom = timeframeZoomedFrom
         }
      }
      // timeframe
      else {
         const { selectedFrom: newSelectedFrom, selectedTo: newSelectedTo } = TimeframeSlider
            .clampTrackToTimeframe(
               timeframeZoomedFrom, timeframeZoomedTo, selectedFrom, selectedTo, true // If selection range is not wanted to be kept when zooming, use this: (prevState.timeframeZoomedTo - prevState.timeframeZoomedFrom === timeframeZoomedTo - timeframeZoomedFrom),
            )
         if (newSelectedFrom !== selectedFrom || newSelectedTo !== selectedTo) {
            newSelectionState.selectedFrom = newSelectedFrom
            newSelectionState.selectedTo = newSelectedTo
         }
      }

      // Manual (when selection props change)
      if (selection !== prevProps.selection) {
         const newSelectionFrom = selection && +selection[0]
         const newSelectionTo = selection && +selection[1]
         if (selectedFrom !== newSelectionFrom || selectedTo !== newSelectionTo) {

            // If timeframe selection is out of zoom bounds, set zoom to match the selection
            const newTimeframeFromState = {}
            if (selection) {
               if (+selection[0] < +timeframeZoomedFrom) {
                  newTimeframeFromState.timeframeZoomedFrom = +selection[0]
               }
               if (+selection[1] > +timeframeZoomedTo) {
                  newTimeframeFromState.timeframeZoomedTo = +selection[1]
               }
            }

            this.setState({
               ...newTimeframeFromState,
               selectedFrom: newSelectionFrom,
               selectedTo: (selectionType === 'timeframe')
                  ? newSelectionTo
                  : undefined,
            })
            if (Object.keys(newTimeframeFromState).length > 0) {
               this.triggerOnZoomSelectionChange(
                  newTimeframeFromState.timeframeZoomedFrom ?? timeframeZoomedFrom,
                  newTimeframeFromState.timeframeZoomedTo ?? timeframeZoomedTo
               )
            }
         }
      }
      // Automatic (when zoom slider has been changed)
      else {
         if (selectionType !== prevProps.selectionType && selectionType === 'time') {
            newSelectionState.selectedTo = undefined
         }
         if (newSelectionState.selectedFrom || newSelectionState.selectedTo) {
            this.onUpdate([
               newSelectionState.selectedFrom || selectedFrom,
               newSelectionState.selectedTo || selectedTo,
            ])
         }
      }
   }

   static clampTrackToTimeframe(timeframeFrom, timeframeTo, trackFrom, trackTo, isSelectionRangeKept) {
      let newSelectedFromTmp; let
         newSelectedToTmp
      let newSelectedFrom; let
         newSelectedTo

      if (trackFrom < timeframeFrom) {
         newSelectedFrom = timeframeFrom
         newSelectedToTmp = (isSelectionRangeKept)
            ? trackTo + (timeframeFrom - trackFrom) // Keep selection range lenght when moving zoom slider
            : trackTo // Don't keep selection range
      }
      if (trackTo > timeframeTo) {
         newSelectedTo = timeframeTo
         newSelectedFromTmp = (isSelectionRangeKept)
            ? trackFrom - (trackTo - timeframeTo) // Keep selection range lenght when moving zoom slider
            : trackFrom // Don't keep selection range lenght when shrinking
      }
      if (timeframeFrom && (newSelectedFromTmp || newSelectedFrom)) {
         newSelectedFrom = clamp(newSelectedFromTmp || newSelectedFrom, timeframeFrom, newSelectedTo - 1)
      }
      if (timeframeTo && (newSelectedToTmp || newSelectedTo)) {
         newSelectedTo = clamp(newSelectedToTmp || newSelectedTo, newSelectedFrom + 1, timeframeTo)
      }
      return {
         selectedFrom: newSelectedFrom ?? trackFrom,
         selectedTo: newSelectedTo ?? trackTo,
      }
   }

   componentWillUnmount() {
      window.removeEventListener('mouseup', this.onMouseUp)
   }

   onMouseUp = () => {
      const { selectedFrom, selectedTo, timeframeZoomedFrom, timeframeZoomedTo } = this.state
      if (this.triggerOnSelectionChangeOnMouseUp) {
         this.triggerOnSelectionChange(selectedFrom, selectedTo)
         this.triggerOnSelectionChangeOnMouseUp = false
      }
      if (this.triggerOnZoomSelectionChangeOnMouseUp) {
         this.triggerOnZoomSelectionChange(timeframeZoomedFrom, timeframeZoomedTo)
         this.triggerOnZoomSelectionChangeOnMouseUp = false
      }

   }

   triggerOnSelectionChange(selectedFrom, selectedTo) {
      if (!this.props.onSelectionChange) return
      const newSelectedFrom = new Date(Math.floor(selectedFrom))
      const newSelectedTo = selectedTo && new Date(Math.ceil(selectedTo))
      this.props.onSelectionChange(newSelectedFrom, newSelectedTo)
   }

   triggerOnZoomSelectionChange(selectedFrom, selectedTo) {
      if (!this.props.onZoomSelectionChange) return
      const newSelectedFrom = new Date(Math.floor(selectedFrom))
      const newSelectedTo = new Date(Math.ceil(selectedTo))
      this.props.onZoomSelectionChange(newSelectedFrom, newSelectedTo)
   }

   onUpdate = ([from, to = undefined]) => {
      const newState = { selectedFrom: from }
      if (to) newState.selectedTo = to
      this.setState(newState)
      if (this.props.onSelectionUpdate) {
         this.props.onSelectionUpdate(
            new Date(from),
            to && new Date(to)
         )
      }
   }

   /** @param {[number, number]} params */
   onTimeframeSelectionUpdate = ([from, to]) => {
      this.setState({
         timeframeZoomedFrom: from,
         timeframeZoomedTo: to,
      })
   }

   /**
    * Formats tick date to proper format depending on timeframe selection range and tick position
    *
    * @param {number} ms
    * @param {boolean} isFirstTick
    * @param {boolean} isLastTick
    * @param {string} timeZone
    * @returns {string}
    */
   formatTick = (ms, isFirstTick, isLastTick, timeZone) => {
      const timeframeZoomedFrom = new Date(this.state.timeframeZoomedFrom)
      const timeframeZoomedTo = new Date(this.state.timeframeZoomedTo)

      const isDifferentDayFromLastTick = this.earlierTickMs
         ? new Date(this.earlierTickMs).getDay() !== new Date(ms).getDay()
         : undefined
      const isDifferentMonthFromLastTick = this.earlierTickMs
         ? new Date(this.earlierTickMs).getMonth() !== new Date(ms).getMonth()
         : undefined
      const isDifferentYearFromLastTick = this.earlierTickMs
         ? new Date(this.earlierTickMs).getFullYear() !== new Date(ms).getFullYear()
         : undefined

      let formatStr = ''

      // Day and month
      if (timeframeZoomedTo.getTime() - timeframeZoomedFrom.getTime() > dayInMS * 1 || isFirstTick || isDifferentDayFromLastTick) {
         formatStr += ` dd.`
      }
      if (timeframeZoomedTo.getTime() - timeframeZoomedFrom.getTime() > dayInMS * 1 || isFirstTick || isDifferentDayFromLastTick || isDifferentMonthFromLastTick) {
         formatStr += ` MMM`
      }
      // Year
      if ((isFirstTick && timeframeZoomedFrom.getFullYear() !== new Date().getFullYear()) || isDifferentYearFromLastTick) {
         formatStr += ` yyyy`
      }
      // Hours, minutes, seconds and milliseconds
      if (timeframeZoomedTo.getTime() - timeframeZoomedFrom.getTime() <= dayInMS * 5) {
         formatStr += ` HH:mm`
      }
      if (timeframeZoomedTo.getTime() - timeframeZoomedFrom.getTime() <= 1000 * 60 * 5) {
         formatStr += `:ss`
      }
      if (timeframeZoomedTo.getTime() - timeframeZoomedFrom.getTime() <= 5000) {
         formatStr += `.SSS`
      }

      this.earlierTickMs = isLastTick ? undefined : ms
      return format(
         timeZone
            ? toZonedTime(new Date(ms), timeZone)
            : new Date(ms),
         formatStr
      )
      // return format(new Date(ms), `MMM yy dd mm HH:mm:ss SSS`)
   }

   /**
    * @param {Date|number} date
    * @returns {string}
    */
   formatDate(date) {
      return format(
         this.props.timeZone ? toZonedTime(date, this.props.timeZone) : date,
         'd. MMM yyyy, HH:mm:ss'
      )
   }


   render() {
      const { timeframeFrom, timeframeTo, timeframeZoomedFrom, timeframeZoomedTo: _timeframeZoomedTo, selectedFrom, selectedTo, numOfTicks } = this.state

      // HACK: App crashes if timeframeZoomedFrom equals to timeframeZoomedFrom, so if they equal, set timeframeZoomedTo to timeframeZoomedTo+1. Handle this some better way..
      const timeframeZoomedTo = (timeframeZoomedFrom !== undefined && timeframeZoomedFrom === _timeframeZoomedTo)
         ? new Date(_timeframeZoomedTo + 1)
         : _timeframeZoomedTo

      const timeFrameIsSingleTime = (this.props.timeframe && +this.props.timeframe[0] === +this.props.timeframe[1])

      const dateTicks = scaleTime()
         .domain([timeframeZoomedFrom, timeframeZoomedTo])
         .ticks(
            (timeframeFrom && !timeFrameIsSingleTime)
               ? numOfTicks
               : 0
         )
         .map((d) => +d)

      const values = []
      if (selectedFrom) values.push(selectedFrom)
      if (selectedTo) values.push(selectedTo)

      const domain = (timeframeFrom && !timeFrameIsSingleTime)
         ? [timeframeFrom, timeframeTo]
         : [0, 1]

      const selectedDomain = (timeframeFrom && !timeFrameIsSingleTime)
         ? [timeframeZoomedFrom, timeframeZoomedTo]
         : [0, 1]

      const timeframe = (!timeFrameIsSingleTime && timeframeFrom) && domain

      return (
         <div className="timeframe-slider" style={{
            padding: '4px 12px 38px',
            backgroundColor: '#000F1A',
            color: 'white',
            pointerEvents: (timeframeFrom && selectedFrom) ? 'auto' : 'none',
            ...this.props.style,
         }}>

            {/* TIMEFRAME TOP ZOOM SLIDER */}
            { this.props.isZoomSliderEnabled
               && <div style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
               }}>

                  <div style={{
                     fontSize: 12,
                     height: 15,
                     minWidth: 'max-content',
                     width: 'max-content',
                     marginBottom: 6,
                     marginRight: 6,
                  }}>
                     { timeframe && this.formatDate(timeframe[0]) }
                     { timeframe && <span style={{ display: 'inline' }}>&nbsp;&nbsp;&nbsp;</span> }
                     { timeframe && '>' }
                  </div>


                  <div style={{
                     width: '100%',
                     position: 'relative',
                     marginLeft: 10,
                     marginRight: 10,
                     marginTop: 9,
                  }}>
                     <ZoomSliderRail
                        timeframe={(!timeFrameIsSingleTime && timeframeFrom) && domain}
                        format={(time) => format(
                           this.props.timeZone ? toZonedTime(time, this.props.timeZone) : time,
                           'd. MMM yyyy, HH:mm:ss'
                        )}
                        timeZone={this.props.timeZone}
                        onBeingChanged={this.props.onBeingChanged}
                        onValueSelected={(selectedTime) => {
                           this.triggerOnSelectionChangeOnMouseUp = true
                           this.triggerOnZoomSelectionChangeOnMouseUp = true
                           const middle = (timeframeZoomedTo - timeframeZoomedFrom) / 2

                           // Jump track to middle of mouse & clamp if out of timeframe bounds
                           const { selectedFrom, selectedTo } = TimeframeSlider.clampTrackToTimeframe(
                              timeframeFrom,
                              timeframeTo,
                              selectedTime - middle,
                              selectedTime + middle,
                              true
                           )
                           this.setState({
                              timeframeZoomedFrom: selectedFrom,
                              timeframeZoomedTo: selectedTo,
                           })
                        }}
                     />
                     {(timeframeFrom && !timeFrameIsSingleTime)
                        && <Handles
                           domain={domain}
                           onSelectionChange={(selection) => {
                              this.triggerOnSelectionChangeOnMouseUp = true
                              this.triggerOnZoomSelectionChangeOnMouseUp = true
                              this.onTimeframeSelectionUpdate(selection)
                           }}
                           onBeingChanged={this.props.onBeingChanged}
                           domainSelected={selectedDomain}
                           trackStyle={{
                              height: 6,
                           }}
                           getHandleElement={() => (
                              <div style={{
                                 width: 16,
                                 height: 16,
                                 position: 'relative',
                                 backgroundColor: '#0075be',
                                 borderRadius: 8,
                              }} />
                           )}
                        />
                     }
                  </div>

                  <div style={{
                     fontSize: 12,
                     height: 15,
                     minWidth: 'max-content',
                     width: 'max-content',
                     textAlign: 'right',
                     marginBottom: 6,
                     marginLeft: 6,
                  }}>
                     { timeframe && '<' }
                     { timeframe && <span style={{ display: 'inline' }}>&nbsp;&nbsp;&nbsp;</span> }
                     { timeframe && this.formatDate(timeframe[1]) }
                  </div>

               </div>
            }
            {/* TIMEFRAME TOP DATES WITHOUT ZOOM SLIDER */}
            { !this.props.isZoomSliderEnabled
               && <div style={{
                  display: 'flex',
                  // alignContent: 'center',
                  // justifyContent: 'center',
               }}>
                  <div style={{
                     display: 'inline-block',
                     fontSize: 12,
                     height: 15,
                     minWidth: 'max-content',
                     width: 'max-content',
                  }}>
                     { timeframe && this.formatDate(timeframe[0]) }
                     { timeframe && <span style={{ display: 'inline' }}>&nbsp;&nbsp;&nbsp;</span> }
                  </div>
                  <div style={{
                     display: 'inline-block',
                     marginLeft: 'auto',
                     fontSize: 12,
                     height: 15,
                     minWidth: 'max-content',
                     width: 'max-content',
                     textAlign: 'right',
                  }}>
                     { timeframe && <span style={{ display: 'inline' }}>&nbsp;&nbsp;&nbsp;</span> }
                     { timeframe && this.formatDate(timeframe[1]) }
                  </div>
               </div>
            }
            <br />

            {/* TIMEFRAME SLIDER */}
            <Slider
               domain={selectedDomain}
               rootStyle={{
                  width: '100%',
                  position: 'relative',
               }}
               values={values}
            >
               <SliderRail
                  onValueSelected={(selectedTime) => {
                     this.triggerOnSelectionChangeOnMouseUp = true
                     if (this.props.selectionType === 'timeframe') {
                        const middle = (selectedTo - selectedFrom) / 2
                        // TODO: onSelectionUpdate
                        this.setState({ // Jump track to middle of mouse. Out of timeframe bounds are checked in componentDidUpdate
                           selectedFrom: selectedTime - middle,
                           selectedTo: selectedTime + middle,
                        })
                     } else {
                        this.onUpdate([selectedTime])
                     }
                  }}
                  onBeingChanged={this.props.onBeingChanged}
                  domain={timeframeFrom && selectedDomain}
                  dataPoints={this.props.dataPoints}
               />
               { selectedFrom && (
                  this.props.selectionType === 'timeframe'
                  // TIMEFRAME
                     ? (
                        <Handles
                           domain={selectedDomain}
                           domainSelected={[selectedFrom, selectedTo]}
                           areHandlesBehindTrack={true}
                           onSelectionChange={(selection) => {
                              this.triggerOnSelectionChangeOnMouseUp = true
                              this.onUpdate(selection)
                           }}
                           onBeingChanged={this.props.onBeingChanged}
                           getHandleElement={(pos, disabled) => (<div style={{
                              height: 28,
                              width: 16,
                              paddingLeft: pos === 'right' ? 8 : 1,
                           }}>
                              <div style={{
                                 position: 'absolute',
                                 width: 7,
                                 height: 32,
                                 top: -2,
                                 borderRadius: 2,
                                 // border: '1px solid #004E7E',
                                 backgroundColor: disabled ? '#999' : '#0075be',
                              }} />
                           </div>)
                           }
                           trackStyle={{
                              backgroundColor: '#0075be33',
                           }}
                        />
                     )
                  // TIME
                     : (
                        <Handle
                           domain={selectedDomain}
                           selectedValue={selectedFrom}
                           onSelectionChange={(selectedTime) => {
                              this.triggerOnSelectionChangeOnMouseUp = true
                              this.onUpdate([selectedTime])
                           }}
                           onBeingChanged={this.props.onBeingChanged}
                           getHandleElement={(disabled) => (<div style={{ height: 28, width: 9 }}>
                              <div style={{
                                 position: 'absolute',
                                 left: 4,
                                 width: 1,
                                 height: 28,
                                 backgroundColor: disabled ? '#666' : '#0075beaa',
                              }} />
                              <div style={{
                                 position: 'absolute',
                                 width: 9,
                                 top: 7,
                                 height: 18,
                                 border: '1px solid #999',
                                 backgroundColor: disabled ? '#999' : '#efefef',
                              }} />
                           </div>)
                           }
                        />
                     )
               )}
               <Ticks values={dateTicks}>
                  {({ ticks }) => (
                     <div>
                        {ticks.map((tick, i) => {
                           const isFirstTick = (i === 0)
                           const isLastTick = (i === ticks.length - 1)
                           return (
                              <Tick
                                 key={tick.id}
                                 tick={tick}
                                 count={ticks.length}
                                 format={(ms) => this.formatTick(ms, isFirstTick, isLastTick, this.props.timeZone)}
                              />
                           )
                        })}
                     </div>
                  )}
               </Ticks>
            </Slider>

            {/* Draw single time text to center if timeframe from and to are equal */}
            { timeFrameIsSingleTime
               && <div style={{
                  position: 'relative',
                  top: '-10px',
                  textAlign: 'center',
                  margin: 'auto',
                  color: 'black',
                  width: '100%',
                  fontWeight: 'normal',
                  height: '0px',
               }}>
                  <p>{timeframeFrom && format(timeframeFrom, 'dd. MMM yyyy HH:mm:ss')}</p>
               </div>
            }
         </div>
      )
   }

}
